<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/products/all" class="desc">
          <b>{{ $t('BUY SKINS') }}</b>
        </router-link>
      </div>
      <div class="center">
        <router-link to="/" class="logo">
          <img src="./../image/logo.svg" class="img"/>
        </router-link>
      </div>
      <div class="right">
        <div 
          v-if="currentLanguage && languages && languages.length > 1"
          class="select-container language-select-container" 
        >
          <Select2 
            v-model="language"
            class="select2 single currency-select" 
            :options="languages"
            :placeholder="currentLanguageName"
            @select="selectLanguage($event)"
          />
        </div>
        <div class="select-container currency-select-container" v-if="currencyOptions && currencyOptions.length > 1">
          <Select2 class="select2 single currency-select" v-model="curr" @select="selectCurr($event)"  :options="currencyOptions" :placeholder="curr" />
        </div>
        <div class="cash" v-if="$parent.isAuth" @click="$parent.openTopup">
          <div class="cash-wallet">
            <img src="./../image/wallet.svg" class="img"/>
            <div class="desc">
              <b>
                <span class="currency">{{ $parent.currency }}</span>
                {{ balance }}
              </b>
            </div>
          </div>
          <button class="button red">
            <img src="./../image/plus.svg" class="img"/>
          </button>
        </div>
        <router-link to="/cart" v-if="$parent.isAuth" :class="['icon icon-cart round', {'gray': !cartContents.length}]">
          <img src="./../image/cart.svg" class="img"/>
          <div v-if="cartContents.length" class="indicator">
            {{cartContents.length}}
          </div>
        </router-link>
        <router-link to="/profile/settings" class="button button-profile" v-if="$parent.isAuth">
          <img src="./../image/user.svg" class="img"/>
        </router-link>
        <div class="link-container" v-else>
          <div class="button" @click="$parent.openSignInModal">
            {{ $t("SIGN IN") }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Select2 from "vue3-select2-component";
import {mapState, mapMutations, mapGetters, mapActions} from "vuex";

export default {
  name: "Header",
  props: ["cartContents", "currencyOptions", "currencyCode", "isAuth", "appBalance"],
  components: {
    Select2,
  },
  data: function () {
    return {
      curr: '',
      balance: '0.00'
    };
  },
  computed: {
    ...mapState({
      languages: ({app: {languages}}) => languages.map(({id, title: text}) => ({id, text})),
      currentLanguage: state => state.app.currentLanguage,
    }),
    ...mapGetters('app', ['currentLanguageName']),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      }
    }
  },
  watch: {
    currencyCode(newValue) {
      this.curr = newValue
    },
    isAuth() {
			this.getBalance();
		},
    appBalance() {
			this.getBalance();
		},
  },
  mounted() {
    this.curr = this.currencyCode;
  },
  methods: {
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
      this.$parent.getTextPages();
      if (this.$route.name == 'TextPage') {
        this.$parent.getTextPageContent(this.$route.params.id);
      }
      this.$parent.resetSelect();
    },
    getBalance() {
      if (this.$parent.isAuth) {
        this.$http.get(process.env.VUE_APP_API + "user/profile?currency=" + this.$parent.currencyCode)
        .then((res) => {
            this.balance = res.data.balance;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.$parent.openSignIn();
          }
        })
      }
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    openSaleSkinModal() {
      this.$emit("openSaleSkinModal");
    },
    logout() {
      this.$emit("logout");
    },
    ...mapMutations('app' , ['setCurrentLanguage']),
    ...mapActions('app', ['changeLocale'])
  },
};
</script>
