<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" :cartContents="cartContents" :currencyCode="currencyCode" @toggleAuthDropdown="toggleAuthDropdown"  @changeCurrency="changeCurrency" />
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{ active: menuVisible }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="header-container">
          <Header :appBalance="appBalance" :cartContents="cartContents" @openSaleSkinModal="openSaleSkinModal" @logout="logout" @toggleAuthDropdown="toggleAuthDropdown" :currencyOptions="currencyOptions" @changeCurrency="changeCurrency" :isAuth="isAuth" :currencyCode="currencyCode" />
        </div>
        <router-view :appBalance="appBalance" @setAppBalance="setAppBalance" @setError="setError" @orderSubmit="orderSubmit" @sendContactForm="sendContactForm" @openOrderModal="openOrderModal" @openOfferDetailsModal="openOfferDetailsModal" @formatDate="formatDate" :addToCartChosenItem="addToCartChosenItem" :currency="currency" @addToCart="addToCart" @goToProduct="goToProduct" @login="login" @forgotPass="forgotPass" @registration="registration" :currencyCode="currencyCode" :cartContents="cartContents" />
        <div class="footer-container">
          <Footer @submitSubscribtion="submitSubscribtion" />
        </div>
        <transition name="fade">
          <TopUpModal
            v-if="topupModalIsVisible"
            @setError="setError"  
          />
        </transition>
        <transition name="fade">
          <OfferDetailsModal v-if="offerDetailsModalIsVisible" @closeOfferDetailsModal="closeOfferDetailsModal" :orderToView="orderToView" />
        </transition>
        <transition name="fade">
          <Login v-if="signInModalIsVisible" @closeSignInModal="closeSignInModal" @openSignUpModal="openSignUpModal" @login="login" @forgotPass="forgotPass" :is-verified="isVerified" @resendConfirmation="resendConfirmationLink"/>
        </transition>
        <transition name="fade">
          <Registration v-if="signUpModalIsVisible" @closeSignUpModal="closeSignUpModal" @openSignInModal="openSignInModal" @registration="registration" />
        </transition>
        <transition name="fade">
          <SaleSkinModal v-if="saleSkinModalIsVisible" @closeSaleSkinModal="closeSaleSkinModal" />
        </transition>
        <transition name="fade">
          <ProfileModal v-if="profileModalIsVisible" />
        </transition>
        <transition name="fade">
          <OrderModal :orderData="orderData" v-show="orderModalIsVisible" @orderSubmit="orderSubmit"/>
        </transition>
        <div class="modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="desc">
    {{
      $te('This Website stores cookies on your computer')
        ? $t('This Website stores cookies on your computer')
        : 'This Website stores cookies on your computer. These cookies collect information about how you navigate our Website and allow us to remember you. We use this information to improve and customise your browsing experience and for analytics and metrics about our visitors on this Website.'
    }}
    <br />
    {{
      $te('Before you proceed with using our Website')
        ? $t('Before you proceed with using our Website')
        : 'Before you proceed with using our Website, please get acquainted with the way we handle a User’s data in our Privacy Policy and the use of Cookies in the Cookies Policy.'
    }}
    <br />
    {{
      $te('You can either accept or decline use of Cookies')
        ? $t('You can either accept or decline use of Cookies')
        : 'You can either accept or decline use of Cookies. If you opt to decline Cookies your information won’t be tracked when you visit this Website.'
    }}
  </div>
            <button class="button" @click="acceptCookies">
              {{ $t("ACCEPT") }}
            </button>
            <button class="button" @click="declineCookies">
              {{ $t("DECLINE") }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
import OfferDetailsModal from "./components/OfferDetailsModal.vue";
import SaleSkinModal from "./components/SaleSkinModal.vue";
import Login from "./components/Login.vue";
import Registration from "./components/Registration.vue";
import { loadScriptsFromString } from "@/utils/load_scripts";
import OrderModal from './components/OrderModal.vue'
import ProfileModal from "./components/ProfileModal.vue";
import TopUpModal from './components/TopUp.vue'
import {mapActions, mapState} from 'vuex';

export default {
  name: "app",
  components: {
    Header,
    Menu,
    Footer,
    OfferDetailsModal,
    SaleSkinModal,
    Registration,
    Login,
    OrderModal,
    ProfileModal,
    TopUpModal
  },
  data: function () {
    return {
      showSelectSort: true,
      pageLoaded: false,
      menuVisible: false,
      offerDetailsModalIsVisible: false,
      saleSkinModalIsVisible: false,
      isAuth: false,
      error: "",
      successMessage: "",
      errorEmail: "",
      successEmail: "",
      orderHistory: [],
      depositHistory: [],
      orderToView: [],
      addToCartChosenItem: "",
      newArrivals: [],
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: "0.00",
      textPageTitle: "",
      textPageHeader: "",
      textPageContent: "",
      productPageItem: "",
      similarProducts: "",
      imgDomain: "",
      textPageList: "",
      hostname: "",
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      footerRequisites: "",
      footerSupportEmail: "",
      footerCopyright: "",
      cookesModalIsVisible: false,
      socialLinks: [],
      isVerified: true,
      orderModalIsVisible: false,
      orderData: {},
      minAmount: '',
      paymentMethods: [],
      appDomain: '',
      authDropdownIsVisible: false,
      profileModalIsVisible: false,
      currencyCode: '',
      currency: '',
      currencyOptions: [],
      topupModalIsVisible: false,
      appBalance: ''
    };
  },
  computed: {
    ...mapState({
      currentLanguage: state => state.app.currentLanguage,
    }),
  },
  watch: {
    currencyCode() {
      if (this.productPageItem) {
        this.getProductPageContent(this.productPageItem.id);
      }
      if (this.isAuth) {
        this.orderHistory = '';
        this.cartContents = '';
        this.totalSum = '';
        this.getOrderHistory();
        this.getDepositHistory();
        this.getCartContents();
        this.getBalance();
      }
    },
    currency() {
      if (this.productPageItem) {
        this.getProductPageContent(this.productPageItem.id);
      }
      if (this.isAuth) {
        this.orderHistory = '';
        this.cartContents = '';
        this.totalSum = '';
        this.getOrderHistory();
        this.getCartContents();
      }
    },
    $route() {
      this.error = '';
      window.scrollTo(0, 0);
      this.closeMenu();
      this.authDropdownIsVisible = false;
      if (this.isAuth) {
        this.getOrderHistory();
      }
    },
  },
  methods: {
    resetSelect() {
      let self = this;
      this.showSelectSort = false;
      setTimeout(function() {
        self.showSelectSort = true;
      }, 100)
    },
    getBalance() {
      if (this.isAuth) {
        this.$http.get(process.env.VUE_APP_API + "user/profile?currency=" + this.currencyCode)
        .then((res) => {
            this.appBalance = res.data.balance;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignIn();
          }
        })
      }
    },
    setAppBalance(balance) {
      this.appBalance = balance;
    },
    openTopup() {
      this.topupModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopup() {
      this.topupModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    submitLoginGoogle() {
      window.location.replace("https://api.skin-flare.com/auth/google/redirect");
    },
    submitLoginSteam() {
      window.location.replace("https://api.skin-flare.com/auth/steam/redirect");
    },
    openProfileModal() {
      this.profileModalIsVisible = true;
    },
    closeProfileModal() {
      this.profileModalIsVisible = false;
    },
    submitSubscribtion(data) {
      this.$http.post(process.env.VUE_APP_API + 'special-offers-subscribe', data)
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          this.successEmail = 'Thank you your message has been sent';
          this.errorEmail = ''
          setTimeout(()=> {
            self.successEmail = '';
          }, 2000)
        } else {
          this.errorEmail = res.data.message
        }
      })
      .catch((res) => {
        this.errorEmail = res.response.data.message
      })
    },
    sendContactForm(data) {
      this.$http.post(process.env.VUE_APP_API + 'feedback', data)
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          this.successMessage = 'Thank you your message has been sent';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    toggleAuthDropdown(state) {
      this.authDropdownIsVisible = state;
    },
    orderSubmit(data) {
      data.currency = this.currency;
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
        if (res.data.status == 'ERROR') {
          this.$router.push({ name: "PaymentFaliPage" });
          this.orderModalIsVisible = false;
        } else {
          window.location = res.data.redirect_url;
        }
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
    openOrderModal(data) {
      if (this.totalSum < 5) {
        this.error =  'Sorry, but minimum order amount is ' +  this.minAmount + ' ' + this.currency;
      } else {
        this.error = '';
        this.orderModalIsVisible = true;
        this.orderData = data;
      }
      
    },
    closeOrderModal() {
      this.orderModalIsVisible = false;
      this.error = '';
    },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          this.paymentMethods = res.data.payload.payment_methods;
          this.minAmount = res.data.payload.min_top_up;
          this.footerRequisites = res.data.payload.requisites;
          this.footerSupportEmail = res.data.payload.support_email;
          this.footerCopyright = res.data.payload.copyright;
          const snippet = res.data.payload.google_tag;

          if (snippet) {
            loadScriptsFromString(snippet);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Tag Manager snippet:", error);
        });
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    clearError() {
      this.error = "";
    },
    setError(error) {
      this.error = error;
    },
    openOfferDetailsModal(item) {
      this.orderToView = item;
      this.offerDetailsModalIsVisible = true;
    },
    closeOfferDetailsModal() {
      this.offerDetailsModalIsVisible = false;
      this.orderToView = {};
    },
    openSaleSkinModal() {
      this.saleSkinModalIsVisible = true;
    },
    closeSaleSkinModal() {
      this.saleSkinModalIsVisible = false;
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.getOrderHistory();
          this.getDepositHistory();
          this.getCartContents();
          this.closeSignInModal();
          this.$router.push({path: '/profile/settings'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },

    resendConfirmationLink(data) {
      this.error = '';
      this.$http
        .post(process.env.VUE_APP_API + "resendConfirmationLink", {email: data})
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = this.$t("Confirmation link has been resent.");
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    forgotPass(data) {
      let self = this;
      this.$http
        .post(process.env.VUE_APP_API + "user/reset-password", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 2000);
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
        });
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            this.closeSignUpModal();
            self.$router.push({path: '/profile/settings'});
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http
        .post(process.env.VUE_APP_API + "logout")
        .then(() => {
          if (this.$route.name != "Home") {
            this.$router.push({ path: "/" });
          }
          this.isAuth = false;
          this.$router.push({ path: "/" });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            console.log(res);
          }
        });
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + "deposit/history?currency=" + this.currencyCode)
      .then((res) => {
        this.depositHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "purchase/history?currency=" + this.currencyCode)
        .then((res) => {
          this.orderHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, "0");
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ":" + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + " " + hoursAndMinutes;

      return format;
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id,
        'count': '1'
      }
      if (item.count > 1) {
        cartItem = {
          'id': id,
          'count': item.count - 1
        }
        this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
        .then(() => {
          self.getCartContents();
        })
        .catch(() => {
        
        })
      } else {
        this.removeFromCart(item.item, id);
      }
      
      
    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/remove', cartItem)
      .then(() => {
        self.getCartContents();
      })
      .catch(() => {
        
      })
    },
    addToCart(item, id) {
     
      let self = this;

      let cartItem = {
        'id': id,
        'count': 1
      }

      for(var i = 0; i < this.cartContents.length; i++) {
        if (this.cartContents[i].item_id == id) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              'id': id,
              'count': cartItemCount + 1
            }
            break;
        }
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
      .then(() => {
        self.addToCartChosenItem = cartItem.id;
        self.getCartContents();
        setTimeout(()=> {
          self.addToCartChosenItem = '';
        }, 2000)
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents?currency=' + this.currencyCode)
          .then((res) => {
            this.cartContents = res.data.cart;

            let total = 0;
            for (let i in this.cartContents) {
              let newPrice;
              if (this.cartContents[i].count > 1) {
                newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
              } else {
                newPrice = parseFloat(this.cartContents[i].item.price);
              }
              total += newPrice;
            }
            this.totalSum = total.toFixed(2);
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignInModal();
            }
          })
    },
    goToProduct(item) {
      this.$router.push({
        path: "/product/" + item.id,
        params: { data: item.id },
      });
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = "";
      this.similarProducts = "";
      this.$http
        .get(process.env.VUE_APP_API + "items/" + id, {params: {currency: this.currencyCode}})
        .then((res) => {
          this.productPageItem = res.data.payload;
          this.similarProducts = res.data.payload.similar;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    goToPage(item) {
      let newItem;
      if (item == "privacy") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].is_privacy) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == "terms") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].is_terms) {
            newItem = this.textPageList[i].id;
          }
        }
      } else {
        newItem = item;
      }
      this.$router.push({
        path: "/pages/" + newItem,
        params: { data: newItem },
      });
      this.getTextPageContent(newItem);
      this.closeSignUpModal();
      this.closeTopup();
    },
    getTextPageContent(id) {
      this.textPageTitle = "";
      this.textPageHeader = "";
      this.textPageContent = "";
      this.$http
        .get(process.env.VUE_APP_API + "static-pages/" + id)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getTextPages() {
      this.$http
        .get(process.env.VUE_APP_API + "static-pages")
        .then((res) => {
          this.textPageList = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    closeSignUpModal() {
      this.signUpModalIsVisible = false;
      this.clearError();
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openSignInModal() {
      this.authDropdownIsVisible = false;
      this.clearError();
      let self = this;
      this.signUpModalIsVisible = false;
      setTimeout(() => {
        self.signInModalIsVisible = true;
      }, 300);
    },
    openSignUpModal() {
      this.authDropdownIsVisible = false;
      this.clearError();
      let self = this;
      this.signInModalIsVisible = false;
      setTimeout(() => {
        self.signUpModalIsVisible = true;
      }, 300);
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    getCurrencies() {
      this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
          const newCurrencies = res.data.payload.map(({
            code: text,
            value: id,
            symbol
          }) => ({
            text,
            id,
            symbol
          }));
          this.currencyOptions = newCurrencies;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency")
          } else {
            this.currencyCode = this.currencyOptions[0].text;
          }
          this.checkCurrency();
          
      })
      .catch(() => {
       
      })
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      this.currency = this.currencyOptions.find((item) => item.text == this.currencyCode).symbol;
    },
    ...mapActions('app', ['fetchLanguages'])
  },
  mounted: async function() {
    let self = this;
    
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname);
    await this.fetchLanguages();
    await this.getCurrencies();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.appDomain = process.env.VUE_APP_DOMAIN;
    this.pageLoaded = true;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();

    self.$http
      .get(process.env.VUE_APP_API + "is-auth")
      .then((res) => {
        console.log("auth: " + res.data.authenticated);
        self.isAuth = res.data.authenticated;

        if (res.data.authenticated) {
          self.getOrderHistory();
          self.getDepositHistory();
          self.getCartContents();
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      });

    self.$http
      .get(process.env.VUE_APP_API + "social-links")
      .then((res) => {
        const data = res.data;

        if (data.status === "OK") {
          self.socialLinks = data.payload || [];
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      });

    self.getGoogleTagManagerSnippet();
    this.getTextPages();
    this.getCurrencies();

  },
};
</script>
