<template>
  <div class="item-wrapper" v-if="item">
    <div class="item-content">
      <div class="preview" v-if="item.img_url" @click="goToSliderItem(item)">
        <img  :src="imgDomain + item.img_url" class="img" />
        <span class="title" v-if="addToCartChosenItem == item.id">{{ $t("Added") }}</span>
      </div>
      <div class="text" @click="goToSliderItem(item)">
        <div class="desc hero" v-if="item.hero">{{ $t(item.hero) }}</div>
        <div class="desc" v-if="item.title">{{ item.title }}</div>
        <div class="desc quality" v-if="item.quality">{{ $t(item.quality) }}</div>
        <div class="price title" v-if="item.price">
          <span class="discount" v-if="item.old_price != '0.00'"
            >{{ item.old_price }} <span class="currency">{{ currency }}</span></span
          >
          <span
            >{{ item.price }} <span class="currency">{{ currency }}</span></span
          >
        </div>
      </div>
      <a @click="$emit('addToCart', item, item.id)" class="button">
        <span>{{ $t("ADD TO CART") }}</span>
      </a>
      <router-link :to="'/products/' + item.quality" class="button orange">{{ $t('BROWSE') }}</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductCard",
  props: ["item", "sliderIsClickble", "currency", "addToCartChosenItem"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit("goToProduct", item);
        }
      }, 200);
    },
  },
};
</script>
